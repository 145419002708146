(function () {
  'use strict';

  var internalId = 0;
  var togglesMap = {};
  var targetsMap = {};

  function $ (selector, context) {
    return Array.prototype.slice.call(
      (context || document).querySelectorAll(selector)
    );
  }

  function getClosestToggle (element) {
    if (element.closest) {
      return element.closest('[data-a11y-toggle]');
    }

    while (element) {
      if (element.nodeType === 1 && element.hasAttribute('data-a11y-toggle')) {
        return element;
      }

      element = element.parentNode;
    }

    return null;
  }

  function handleToggle (toggle) {
    var target = toggle && targetsMap[toggle.getAttribute('aria-controls')];

    if (!target) {
      return false;
    }

    var toggles = togglesMap['#' + target.id];
    var isExpanded = target.getAttribute('aria-hidden') === 'false';

    target.setAttribute('aria-hidden', isExpanded);
    target.setAttribute('data-a11y-toggle-open', !isExpanded);
    if (isExpanded) {
      target.removeAttribute('data-a11y-toggle-open');
    }
    toggles.forEach(function (toggle) {
      toggle.setAttribute('aria-expanded', !isExpanded);
    });
    
    
    
  }
  
  function handleClose (toggle) {
    var target = toggle && targetsMap[toggle.getAttribute('aria-controls')];
    
    if (!target) {
      return false;
    }

    var toggles = togglesMap['#' + target.id];
    var isExpanded = target.getAttribute('aria-hidden') === 'false';

    target.setAttribute('aria-hidden', true);
    target.setAttribute('data-a11y-toggle-open', false);
    if (isExpanded) {
      target.removeAttribute('data-a11y-toggle-open');
    }
    toggles.forEach(function (toggle) {
      toggle.setAttribute('aria-expanded', false);
    });
  }  

  var initA11yToggle = function (context) {
    togglesMap = $('[data-a11y-toggle]', context).reduce(function (acc, toggle) {
      var selector = '#' + toggle.getAttribute('data-a11y-toggle');
      acc[selector] = acc[selector] || [];
      acc[selector].push(toggle);
      return acc;
    }, togglesMap);

    var targets = Object.keys(togglesMap);
    targets.length && $(targets).forEach(function (target) {
      var toggles = togglesMap['#' + target.id];
      var isExpanded = target.hasAttribute('data-a11y-toggle-open');
      var labelledby = [];

      toggles.forEach(function (toggle) {
        toggle.id || toggle.setAttribute('id', 'a11y-toggle-' + internalId++);
        toggle.setAttribute('aria-controls', target.id);
        toggle.setAttribute('aria-expanded', isExpanded);
        labelledby.push(toggle.id);
      });

      target.setAttribute('aria-hidden', !isExpanded);
      target.hasAttribute('aria-labelledby') || target.setAttribute('aria-labelledby', labelledby.join(' '));

      targetsMap[target.id] = target;
    });
  };

  document.addEventListener('DOMContentLoaded', function () {
    initA11yToggle();
  });

  document.addEventListener('click', function (event) {
    var toggle = getClosestToggle(event.target);
    handleToggle(toggle);
    
    var toggledList = getParentToggledList(event.target);
    document.querySelectorAll('[data-a11y-toggle]').forEach((dropdown) => {
      if (dropdown.getAttribute('data-a11y-autohide') != 'false'
         && (
           toggle == null 
           || (
              toggle != null 
              && dropdown.getAttribute('data-a11y-toggle') != toggle.getAttribute('data-a11y-toggle')
              )
           )
           && (
              toggledList == null 
              || (
              toggledList != null 
              && dropdown.getAttribute('data-a11y-toggle') != toggledList.getAttribute('data-a11y-toggle')
              )
           )
      ){
          handleClose(dropdown);
      }
    });
  });

  document.addEventListener('keyup', function (event) {
    if (event.which === 13 || event.which === 32) {
      var toggle = getClosestToggle(event.target);
      if (toggle && toggle.getAttribute('role') === 'button') {
        handleToggle(toggle);
      }
    }
  });
  
  
  function getParentToggledList (element) {
    const a11yPrefix = 'a11y-toggle';
    if (element.closest) {
      const toggler = element.closest('[aria-labelledby^="'+a11yPrefix+'"]');
      if (toggler === null) {
        return null;
      }
      return document.getElementById(toggler.getAttribute('aria-labelledby'));
    }

    while (element) {
      if (element.nodeType === 1 && element.hasAttribute('aria-labelledby') && element.hasAttribute('data-a11y-toggle-open')) {
        const togglerId = element.getAttribute('aria-labelledby');
        if (togglerId.startsWith(a11yPrefix)) {
          return document.getElementById(togglerId);
        }
      }

      element = element.parentNode;
    }

    return null;
  }

  window && (window.a11yToggle = initA11yToggle);
})();
