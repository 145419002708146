$(document).ready(function(){


    /*
     * Collections Add / delete items
     */

    /*if ($(".custom-collection-row").length) {

        function addItem(addButton) {
            var containerSelector = addButton.dataset.container;
            var container = document.querySelector(containerSelector);
            var prototype = container.dataset.prototype;

            var index = container.querySelectorAll('.custom-collection-row-entry').length;

            var newItem = prototype.replace(/__name__/g, index);

            // Create a wrapper element for the new item
            var itemWrapper = document.createElement('div');
            itemWrapper.classList.add('custom-collection-row-entry');
            itemWrapper.innerHTML = newItem;

            container.appendChild(itemWrapper);

            var addItemButtons = itemWrapper.getElementsByClassName('add-item');
            for (var i = 0; i < addItemButtons.length; i++) {
                addItemButtons[i].addEventListener('click', function() {
                    addItem(this);
                });
            }


            var deleteButtons = container.querySelectorAll('.delete-button');
            var newDeleteButton = deleteButtons[deleteButtons.length - 1];
            newDeleteButton.addEventListener('click', function() {
                deleteItem(this);
            });
        }

        function deleteItem(deleteButton) {

            var item = deleteButton.parentNode.closest('.custom-collection-row-entry');
            item.parentNode.removeChild(item);
        }

        var addButtons = document.getElementsByClassName('add-item');
        for (var i = 0; i < addButtons.length; i++) {
            addButtons[i].addEventListener('click', function() {
                addItem(this);
            });
        }

        var deleteButtons = document.getElementsByClassName('delete-button');
        for (var i = 0; i < deleteButtons.length; i++) {
            deleteButtons[i].addEventListener('click', function() {
                deleteItem(this);
            });
        }
    }*/

    /*if ($('.white-popup-auto-close').length) {
        $('.white-popup-auto-close').each(function(i, obj) {
            setTimeout(function(){
                $(obj).addClass('is-hidden');
                $(obj).remove();
            }, 3000);
        });
    }*/

    if ($('.notification-auto-close').length) {
        $('.notification-auto-close .delete').on('click', function(){
            $(this).closest('.notification-auto-close').addClass('is-hidden');
            $(this).closest('.notification-auto-close').remove();
        });
        $('.notification-auto-close').each(function(i, obj) {
            setTimeout(function(){
                $(obj).addClass('is-hidden');
                $(obj).remove();
            }, 3000);
        });
    }


    if ($('#solicitude_step2_form_project_keywords').length && $('#solicitude_step2_form_project_keywordComment').length) {
        let checked = false;
        $('#solicitude_step2_form_project_keywords input[data-comment="1"]').each(function(){
            if ($(this).is(':checked')) {
                checked = true;
            }
        });
        $('#solicitude_step2_form_project_keywordComment').closest('div.column').toggle(checked);
        $('#solicitude_step2_form_project_keywords input[data-comment="1"]').on('change', function(){
            let checked = false;
            $('#solicitude_step2_form_project_keywords input[data-comment="1"]').each(function(){
                if ($(this).is(':checked')) {
                    checked = true;
                }
            });
            $('#solicitude_step2_form_project_keywordComment').closest('div.column').toggle(checked);
        });
    }
    
    if ($('#project_update_funding_period_form_durationApproved').length
        && $('#project_update_funding_period_form_fundingStart').length
        && $('#project_update_funding_period_form_fundingEnd').length
    ) {
		function updateFundingPeriodEnd()
		{
			const durationMonth = parseInt($('#project_update_funding_period_form_durationApproved').val(), 10);
			const startDate = $('#project_update_funding_period_form_fundingStart').val();
			if (durationMonth != '' && startDate !='' ) {
				let date = new Date(startDate);
				date.setMonth(date.getMonth()+durationMonth);
				date.setDate(date.getDate()-1);
				$('#project_update_funding_period_form_fundingEnd').val(date.toISOString().substring(0,10));
			}
		}
		$('#project_update_funding_period_form_durationApproved').on('change', updateFundingPeriodEnd);
		$('#project_update_funding_period_form_fundingStart').on('change', updateFundingPeriodEnd);
	}

});