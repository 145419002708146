$(document).ready(function () {
  ("use strict");

  // Menú: afegim botó i ocultem menú quan hi ha js
  // $(".login-toggle")
  //   .before(
  //     '<button class="menu-toggle upp has-text-weight-bold is-size-9" type="button" aria-expanded="false" aria-controls="menu">Menú</button>'
  //   );
  // $("#menu").attr("hidden", "true");

  // ----
  // menú (https://polypane.rocks/blog/the-perfect-responsive-menu-2019/)
  // ----
  // var toggleMenu = document.querySelector(".menu-toggle");
  // var menu = document.querySelector("#menu");

  // if (toggleMenu) {
  //   toggleMenu.addEventListener("click", function () {
  //     var open = JSON.parse(toggleMenu.getAttribute("aria-expanded"));
  //     toggleMenu.setAttribute("aria-expanded", !open);
  //     menu.hidden = !menu.hidden;
  //   });
  // }

  // $(".menu-toggle").on("click", function () {
  //   // afegir classe al botó
  //   $(this).toggleClass("is-active");
  //   // afegir classe al body
  //   $("body").toggleClass("js-menu-open");
  // });

  // -------------------------------
  // Menú principal amb desplegables (https://www.w3.org/WAI/tutorials/menus/flyout/#use-button-as-toggle)
  // -------------------------------

  // var menuItems = document.querySelectorAll("#menu li.has-submenu");
  // Array.prototype.forEach.call(menuItems, function (el, i) {
  //   var activatingA = el.querySelector("a");
  //   var btn =
  //     '<button><span><span class="is-sr-only">Muestra el submenú para “' +
  //     activatingA.text +
  //     "”</span></span></button>";
  //   activatingA.insertAdjacentHTML("afterend", btn);
  //   // activatingA.setAttribute("aria-expanded", "false");

  //   el.querySelector("button").addEventListener("click", function (event) {
  //     var currentMenu = this.parentNode;
  //     Array.prototype.forEach.call(menuItems, function (el, i) {
  //       if (currentMenu !== el) {
  //         el.classList.remove("open");
  //         el.classList.add("close");
  //         //el.querySelector("a").setAttribute("aria-expanded", "false");
  //         el.querySelector("button").setAttribute("aria-expanded", "false");
  //       }
  //     });
  //     //console.log(this.parentNode.className);
  //     if (this.parentNode.classList.contains("close")) {
  //       this.parentNode.classList.add("open");
  //       this.parentNode.classList.remove("close");
  //       /*this.parentNode
  //         .querySelector("a")
  //         .setAttribute("aria-expanded", "true");*/
  //       this.parentNode
  //         .querySelector("button")
  //         .setAttribute("aria-expanded", "true");
  //     } else {
  //       this.parentNode.classList.add("close");
  //       this.parentNode.classList.remove("open");
  //       /*this.parentNode
  //         .querySelector("a")
  //         .setAttribute("aria-expanded", "false");*/
  //       this.parentNode
  //         .querySelector("button")
  //         .setAttribute("aria-expanded", "false");
  //     }
  //     event.preventDefault();
  //   });
  // });

  // ---------
  // skip link (https://www.bignerdranch.com/blog/web-accessibility-skip-navigation-links/)
  // ---------

  // bind a click event to the 'skip' link
  $(".skip-link").click(function (event) {
    // strip the leading hash and declare
    // the content we're skipping to
    var skipTo = "#" + this.href.split("#")[1];

    // Setting 'tabindex' to -1 takes an element out of normal
    // tab flow but allows it to be focused via javascript
    $(skipTo)
      .attr("tabindex", -1)
      .on("blur focusout", function () {
        // when focus leaves this element,
        // remove the tabindex attribute
        $(this).removeAttr("tabindex");
      })
      .focus(); // focus on the content container
  });

  // ----------
  // scroll top
  // ----------
  $(window).on('scroll', function() {
    if ($(this).scrollTop() > 400) {
      $(".scrolltop").addClass("visible");
    } else {
      $(".scrolltop").removeClass("visible");
    }
  });

  // -------------
  // Fixed element
  // -------------
  // var elementOffset = $('.actions-wrapper').offset().top;
  // var actionsHeight = $('.actions-wrapper').outerHeight();

  // $(window).on('scroll', function() {
  //   var scrollTop = $(window).scrollTop();

  //   if (scrollTop >= elementOffset) {
  //     $('.actions-wrapper').addClass('is-fixed');
  //     $('.results-list').css('padding-top', actionsHeight);
  //   } else {
  //     $('.actions-wrapper').removeClass('is-fixed');
  //     $('.results-list').removeAttr('style');
  //   }
  // });

  // var topElementOffset = $('.actions-wrapper--top').offset().top;
  // var bottomElementOffset = $('.actions-wrapper--bottom').offset().top;
  // var actionsHeight = $('.actions-wrapper--top').outerHeight();

  // $(window).on('scroll', function() {
  //   var scrollTop = $(window).scrollTop();

  //   if (scrollTop >= topElementOffset && scrollTop + $(window).height() < bottomElementOffset) {
  //     $('.actions-wrapper--top').addClass('is-fixed');
  //     $('.results-list').css('padding-top', actionsHeight);
  //   } else {
  //     $('.actions-wrapper--top').removeClass('is-fixed');
  //     $('.results-list').removeAttr('style');
  //   }

  // });


  // -----------
  // File upload
  // -----------
  // $('input[type="file"]').change(function () {
  //   var i = $(this).next().find(".file-label").clone();
  //   var file = $('input[type="file"]')[0].files[0].name;
  //   $(this).next().find(".file-label").text(file);
  // });

  // ----
  // Tabs https://inclusive-components.design/tabbed-interfaces/
  // ----
  if ($(".tabbed").length > 0) {
    $(".tabbed").each(function (index, element) {
      // Get relevant elements and collections
      var tabbed = element;
      var tablist = tabbed.querySelector("ul.tab-menu");
      var tabs = tablist.querySelectorAll("a");
      var panels = tabbed.querySelectorAll(".tab-panel");

      // The tab switching function
      var switchTab = function switchTab(oldTab, newTab) {
        if (newTab.hash == '') {
          window.location.href=newTab.getAttribute('href');
        }
        newTab.focus(); // Make the active tab focusable by the user (Tab key)

        newTab.removeAttribute("tabindex"); // Set the selected state

        newTab.setAttribute("aria-selected", "true");
        oldTab.removeAttribute("aria-selected");
        oldTab.setAttribute("tabindex", "-1"); // Get the indices of the new and old tabs to find the correct
        // tab panels to show and hide

        var index = Array.prototype.indexOf.call(tabs, newTab);
        var oldIndex = Array.prototype.indexOf.call(tabs, oldTab);
        panels[oldIndex].hidden = true;
        panels[index].hidden = false;
      }; // Add the tablist role to the first <ul> in the .tabbed container

      tablist.setAttribute("role", "tablist"); // Add semantics are remove user focusability for each tab

      Array.prototype.forEach.call(tabs, function (tab, i) {
        tab.setAttribute("role", "tab");
        //	    tab.setAttribute("id", "tab" + (i + 1));
        tab.setAttribute("tabindex", "-1");
        tab.parentNode.setAttribute("role", "presentation"); // Handle clicking of tabs for mouse users

        tab.addEventListener("click", function (e) {
          e.preventDefault();
          var currentTab = tablist.querySelector("[aria-selected]");

          if (e.currentTarget !== currentTab) {
            switchTab(currentTab, e.currentTarget);
          }
        }); // Handle keydown events for keyboard users

        tab.addEventListener("keydown", function (e) {
          // Get the index of the current tab in the tabs node list
          var index = Array.prototype.indexOf.call(tabs, e.currentTarget); // Work out which key the user is pressing and
          // Calculate the new tab's index where appropriate

          var dir =
            e.which === 37
              ? index - 1
              : e.which === 39
                ? index + 1
                : e.which === 40
                  ? "down"
                  : null;

          if (dir !== null) {
            e.preventDefault(); // If the down key is pressed, move focus to the open panel,
            // otherwise switch to the adjacent tab

            dir === "down"
              ? panels[i].focus()
              : tabs[dir]
                ? switchTab(e.currentTarget, tabs[dir])
                : void 0;
          }
        });
      }); // Add tab panel semantics and hide them all

      Array.prototype.forEach.call(panels, function (panel, i) {
        panel.setAttribute("role", "tabpanel");
        panel.setAttribute("tabindex", "-1");
        var id = panel.getAttribute("id");
        panel.setAttribute("aria-labelledby", tabs[i].id);
        panel.hidden = true;
      }); // Initially activate the first tab and reveal the first tab panel

      
      // per forçar el tab seleccionat per hash
      let anchor = document.location.hash;
      let tabSelected = false;
      if (anchor.length) {
        Array.prototype.forEach.call(tabs, function (tab, i) {
          if (tab.getAttribute('href') == anchor) {
            tabs[i].removeAttribute("tabindex");
            tabs[i].setAttribute("aria-selected", "true");
            panels[i].hidden = false;
            tabSelected = true;
          }
        })
      }
      if (!tabSelected) {
        tabs[0].removeAttribute("tabindex");
        tabs[0].setAttribute("aria-selected", "true");
        panels[0].hidden = false;
      }
    });
    

  }

  // -------
  // Pop-ups
  // -------
  $('.open-popup').magnificPopup({
    type: 'inline',
    closeMarkup: '<button aria-label="%title%" aria-hidden="true" focusable="false" type="button" class="mfp-close mt-0"><svg  width="20" height="20" xmlns="http://www.w3.org/2000/svg"><g stroke="#000" fill="none" fill-rule="evenodd"><path d="m1 1 18 18M19 1 1 19"/></g></svg></button>'
  });

  // --------------
  // Fixed tab-menu
  // --------------
  if ($(".tab-menu").length) {
    var tabMenu = $(".tab-menu");
    var tabMenuTop = tabMenu.offset().top;

    $(window).scroll(function() {
      if ($(window).scrollTop() >= tabMenuTop - 20) {
        tabMenu.addClass("fixed");
        if ($(".tabbed-sidebar").length) {
			$(".tab-section").addClass('is-hidden');
		}
      } else {
        tabMenu.removeClass("fixed");
        if ($(".tabbed-sidebar").length) {
			$(".tab-section").removeClass('is-hidden');
		}
      }
    });
  }

  /*
    * Collections Add / delete items
  */

  if ($(".custom-collection-row").length) {

    function addItem(addButton) {
        var containerSelector = addButton.dataset.container;
        var container = document.querySelector(containerSelector);
        var prototype = container.dataset.prototype;

        var index = container.querySelectorAll('.custom-collection-row-entry').length;

        var newItem = prototype.replace(/__name__/g, index);

        // Create a wrapper element for the new item
        var itemWrapper = document.createElement('div');
        itemWrapper.classList.add('custom-collection-row-entry');
        itemWrapper.innerHTML = newItem;

        container.appendChild(itemWrapper);
        window.a11yToggle(itemWrapper);

        var addItemButtons = itemWrapper.getElementsByClassName('add-item');
        for (var i = 0; i < addItemButtons.length; i++) {
            addItemButtons[i].addEventListener('click', function() {
                addItem(this);
            });
        }


        var deleteButtons = container.querySelectorAll('.delete-button');
        var newDeleteButton = deleteButtons[deleteButtons.length - 1];
        newDeleteButton.addEventListener('click', function(event) {
            event.preventDefault();
            deleteItem(this);
        });
    }

    function deleteItem(deleteButton) {

        var item = deleteButton.parentNode.closest('.custom-collection-row-entry');
        item.parentNode.removeChild(item);
    }

    var addButtons = document.getElementsByClassName('add-item');
    for (var i = 0; i < addButtons.length; i++) {
        addButtons[i].addEventListener('click', function() {
            addItem(this);
        });
    }

    var deleteButtons = document.getElementsByClassName('delete-button');
    for (var i = 0; i < deleteButtons.length; i++) {
        deleteButtons[i].addEventListener('click', function() {
            deleteItem(this);
        });
    }
  }
});

// -----------
// Collapsible (https://inclusive-components.design/collapsible-sections/)
// -----------
// (function () {
//   // Get all the headings
//   const headings = document.querySelectorAll('.collapsible__title')

//   Array.prototype.forEach.call(headings, heading => {
//     // Give each <h2> a toggle button child
//     // with the SVG plus/minus icon
//     heading.innerHTML = `
//     <div class="has-background-grey-light is-flex is-justify-content-space-between is-align-items-center pr-6 is-relative">
//       <button type="button" aria-expanded="false" class="py-5 px-m-2xl">
//         ${heading.textContent}
//         <svg width="26.828" height="15.093" xmlns="http://www.w3.org/2000/svg"><path d="M1.414 1.942 13.15 13.68 25.414 1.414" stroke="#0078BC" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="square"/></svg>
//       </button>
//       <button type="button" class="delete delete-button" aria-label="Delete"></button>
//     </div>
//     `
//     // Function to create a node list
//     // of the content between this heading and the next
//     const getContent = (elem) => {
//       let elems = []
//       while (elem.nextElementSibling && elem.nextElementSibling.className !== 'collapsible__title') {
//         elems.push(elem.nextElementSibling)
//         elem = elem.nextElementSibling
//       }

//       // Delete the old versions of the content nodes
//       elems.forEach((node) => {
//         node.parentNode.removeChild(node)
//       })

//       return elems
//     }

//     // Assign the contents to be expanded/collapsed (array)
//     let contents = getContent(heading)

//     // Create a wrapper element for `contents` and hide it
//     let wrapper = document.createElement('div')
//     wrapper.hidden = true

//     // Add each element of `contents` to `wrapper`
//     contents.forEach(node => {
//       wrapper.appendChild(node)
//     })

//     // Add the wrapped content back into the DOM
//     // after the heading
//     heading.parentNode.insertBefore(wrapper, heading.nextElementSibling)

//     // Assign the button
//     let btn = heading.querySelector('button')

//     // const bodyElement = document.querySelector('body');
//     //   bodyElement.classList.add('tabs-loaded');

//     btn.onclick = () => {
//       // Cast the state as a boolean
//       let expanded = btn.getAttribute('aria-expanded') === 'true' || false

//       // Switch the state
//       btn.setAttribute('aria-expanded', !expanded)
//       // Switch the content's visibility
//       wrapper.hidden = expanded
//     }
//   })

//   if ($('.collapsible__title').length > 0 && window.location.hash.indexOf('#entry-') > -1 && $(window.location.hash).length > 0) {
//     $(window.location.hash).find('button').attr('aria-expanded', true);
//     $(window.location.hash).next().show();
//     $('html, body').animate({
//         scrollTop: $(window.location.hash).parent().offset().top
//     }, 1000);
//   }

// })();


vex.defaultOptions.className = 'vex-theme-default';

$(document).ready(function(){
  if ($('#alert-message').length && Cookies.get('alert-message') !== 'true') {
    var elem = $('#alert-message');
    vex.dialog.alert({
      unsafeMessage: elem.text(),
      className: 'vex-theme-default vex-custom-login',
      buttons: [
        $.extend({}, vex.dialog.buttons.OK, {
          text: elem.data('label-ok')
        })
      ],
      callback: function() {
        Cookies.set('alert-message', 'true', { expires: 20 });
      }
    });
  }
});




/*
  * Session refresh
*/
$(document).ready(function(){
    var refreshTime = 60000; // every 1 minutes in milliseconds
    window.setInterval( function() {
        $.ajax({
            cache: false,
            type: "GET",
            url: "/session-update?t=" + Math.floor(Date.now() / 1000),
            success: function(data) {
            }
        });
    }, refreshTime );
});