var idioma = $('html').attr('lang');

/*
 * Control modificació de formularis
 */
if ($('form.validateForm').length) {
  var catcher = function() {
    var changed = false;
    $('form.validateForm').each(function() {
      if ($(this).data('initialForm') != $(this).serialize()) {
        changed = true;
        $(this).addClass('changed');
      } else {
        $(this).removeClass('changed');
      }
    });
    if (changed) {
      return 'One or more forms on this page have changed.  Are you sure you want to leave this page?';

    }
  };

  $(function() {
    $('form.validateForm').each(function() {
      $(this).data('initialForm', $(this).serialize());
    });
    $(window).bind('beforeunload', catcher);
  });
  $("form.validateForm button[type='submit']").click(function() {
    const form = $(this).closest('form');
    //treure el avis de canvis no guardats
    $(window).unbind('beforeunload', catcher);
    form.submit();
  });
}

//jQuery.validator.addMethod('exactlength', function(value, element, param) {
//    'use strict';
//    return this.optional(element) || value.length == param;
//}, $.validator.format('Please enter exactly {0} characters.'));
//
//
//jQuery.validator.addClassRules("emailDuplicatedControl", {
//	emailDuplicatedControl: true
//});
//
//jQuery.validator.addMethod('emailDuplicatedControl', function(value, element, param) {
//    'use strict';
//    var data = {email: value};
//    result = $.ajax({
//        type: 'POST',
//        url: '/validation/ajax/email-duplicated',
//        data: data,
//        async: false
//    }).responseText;
//
//    return this.optional(element) || result != 1;
//}, 'Please enter another value, this email is already used.');
//
//$('.validateForm').each(function() {//per cada formulari
//    'use strict';
//
//    $(this).find('.protecciodades a').attr('target', '_blank');
//
//    //validacio formulari
//    $(this).validate({
//        errorPlacement: function(error, element) {
//            if (element.attr('type') === 'checkbox' || element.attr('type') === 'radio') {
//                if (element.closest('div').find('input').length > 1) {
//                    error.insertAfter(element.closest('div').find('p.label'));
//                } else {
//                    error.insertAfter(element.parent());
//                }
//            } else if (element.is("select")) {
//                if (element.closest('div.select').length > 1) {
//                    error.insertAfter(element.closest('div.select'));
//                } else {
//                    error.insertAfter(element.parent());
//                }
//            } else {
//                error.insertAfter(element);
//            }
//        }
//    });
//});


vex.defaultOptions.className = 'vex-theme-default';

$(document).ready(function(){

if ($('form').length) {
  $('form').each(function(){
    const form = $(this);
    form.on('submit', function (submitEvent) {
		var buttonClicked = $(document.activeElement);
		if (buttonClicked.hasClass('confirmSubmit')) {
		  submitEvent.preventDefault();
		}
	}); 
	form.find('.confirmSubmit').each( function(){
		$(this).on('click', function(clickEvent) {
	      clickEvent.preventDefault();
	      var elem = $(this);
	      vex.dialog.confirm({
	        message: elem.data('message'),
	        buttons: [
	          $.extend({}, vex.dialog.buttons.YES, {
	            text: elem.data('label-ok')
	          }), $.extend({}, vex.dialog.buttons.NO, {
	            text: elem.data('label-cancel')
	          })
	        ],
	        callback: function(value) {
	          if (value) {
	            elem.unbind('click');
	            elem.click();
	          }
	        }
	      });
	    });
	})
  });
}


$('a.confirmLink').each( function(){
	$(this).on('click', function(clickEvent) {
      clickEvent.preventDefault();
      var elem = $(this);
      vex.dialog.confirm({
        message: elem.data('message'),
        buttons: [
          $.extend({}, vex.dialog.buttons.YES, {
            text: elem.data('label-ok')
          }), $.extend({}, vex.dialog.buttons.NO, {
            text: elem.data('label-cancel')
          })
        ],
        callback: function(value) {
          if (value) {
            window.location.href = elem.attr('href');
          }
        }
      });
    });
})

});